.one-col-box[data-v-81f37c9e] {
  min-height: 600px;
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;
}
.one-col-box.wide[data-v-81f37c9e] {
  max-width: 980px;
}
.one-col-box.even-wider[data-v-81f37c9e] {
  max-width: 1018px;
}
.one-col-box.rounded-corners[data-v-81f37c9e] {
  border-radius: 6px;
}
.one-col-box.no-background[data-v-81f37c9e] {
  background-color: rgba(0, 0, 0, 0);
}
.one-col-box.no-padding > .content[data-v-81f37c9e] {
  padding: 0;
}
.one-col-box > .content[data-v-81f37c9e] {
  padding: 0px 20px;
  overflow: hidden;
}
@media (min-width: 835px) {
.one-col-box[data-v-81f37c9e] {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px);
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
.one-col-box.start-higher[data-v-81f37c9e] {
    -webkit-transform: translateY(-78px);
            transform: translateY(-78px);
}
.one-col-box.no-transform[data-v-81f37c9e] {
    -webkit-transform: none;
            transform: none;
    margin-bottom: 50px;
}
}