.the-title-section[data-v-3b9cef7e] {
  background-image: url(../img/bg-horizontal-combined-stripe-title.896ce7d7.jpg);
  background-color: #0028a0;
  background-repeat: repeat;
  background-position: top center;
  background-size: cover;
  margin-top: 64px;
}
.the-title-section.even-bottom > div h1 + h2[data-v-3b9cef7e] {
  padding: 0 0 31px;
}
.the-title-section.center-on-mobile > div h1[data-v-3b9cef7e],
.the-title-section.center-on-mobile > div h2[data-v-3b9cef7e] {
  text-align: center;
}
.the-title-section.main-title-only > div h1[data-v-3b9cef7e] {
  padding: 38px 0 43px 0;
  font-size: 1.71429rem;
}
.the-title-section.padded > div h1[data-v-3b9cef7e] {
  padding: 30px 0 34px 0;
}
.the-title-section > div[data-v-3b9cef7e] {
  min-height: 88px;
  max-width: 960px;
  margin: 0px auto;
  padding: 0 20px;
}
.the-title-section > div h1[data-v-3b9cef7e] {
  font-size: 2rem;
  color: #fff;
  margin: 0;
  line-height: 1.21886em;
  font-size: 1.71429rem;
  padding: 24px 0 8px 0;
  font-weight: 400;
}
.the-title-section > div h2[data-v-3b9cef7e] {
  font-size: 1rem;
  color: #fff;
  font-style: italic;
  text-align: left;
  margin: 0;
  padding: 0 0 24px;
  line-height: 1.21886em;
  font-weight: 300;
}
@media (min-width: 835px) {
.the-title-section[data-v-3b9cef7e] {
    margin-top: 50px;
}
.the-title-section.center-on-mobile > div h1[data-v-3b9cef7e],
  .the-title-section.center-on-mobile > div h2[data-v-3b9cef7e] {
    text-align: left;
}
.the-title-section.padded > div h1[data-v-3b9cef7e] {
    padding: 38px 24px;
}
.the-title-section.padded.main-title-only > div h1[data-v-3b9cef7e] {
    font-size: 2.2rem;
}
.the-title-section.main-title-only > div h1[data-v-3b9cef7e] {
    font-size: 2.286rem;
    padding: 34px 32px 38px 32px;
}
.the-title-section > div[data-v-3b9cef7e] {
    min-height: 185px;
}
.the-title-section > div h1[data-v-3b9cef7e] {
    font-size: 2.28571rem;
    padding: 25px 0 5px 0;
}
.the-title-section > div h2[data-v-3b9cef7e] {
    padding: 0;
}
}
@media (min-width: 960px) {
.the-title-section.padded > div h1[data-v-3b9cef7e] {
    padding: 38px 32px;
}
.the-title-section > div[data-v-3b9cef7e] {
    padding: 0 10px;
}
}